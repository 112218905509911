import { render, staticRenderFns } from "./ModalNewQuestion.vue?vue&type=template&id=4ebedc96&scoped=true"
import script from "./ModalNewQuestion.vue?vue&type=script&setup=true&lang=js"
export * from "./ModalNewQuestion.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ModalNewQuestion.vue?vue&type=style&index=0&id=4ebedc96&prod&lang=scss&scoped=true"
import style1 from "./ModalNewQuestion.vue?vue&type=style&index=1&id=4ebedc96&prod&leng=scss&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ebedc96",
  null
  
)

export default component.exports