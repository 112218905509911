<template>
  <section class="Advanced-questions ml-3">
    <section>
      <FormItem
        :title="$t('opportunities.name')"
        :titleColor="'var(--secondary-500)'"
        :sub-title="$t('opportunities.description')"
        class="oportunity-description"
        data-anime="top"
      />
      <FormItem
        class="oportunity-profit"
        :title="$t('opportunities.profit')"
        :sub-title="$t('opportunities.profit_description')"
      >
        <b-form-checkbox size="lg" v-model="greennProfit" switch />
      </FormItem>
    </section>
    <section class="Advanced__tabs">
      <b-tabs class="w-100" content-class="w-100" nav-wrapper-class="w-100">
        <b-tab
          v-for="(tab, index) in tabs.filter((item) => item.show)"
          :key="index"
          lazy
          @click="selectedTab = tab"
        >
          <template #title>
            {{ $t(tab.title) }}
          </template>
        </b-tab>
      </b-tabs>
    </section>
    <component
      :is="selectedTab.component"
      :productQuestions="productQuestions"
      :opportunitySettings="opportunitySettings"
    />
  </section>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import FormItem from "./shared/FormItem.vue";
import { useStore } from "@/utils/useStore";

import QuestionsAnswers from "./Advanced/QuestionsAnswers.vue";
import KittenTimeProduct from "./Advanced/KittenTimeProduct.vue";
import Permissions from "@/utils/permissions.js";
import debounce from 'lodash/debounce';

const store = useStore();

const debouncedSetGreennProfit = debounce((event) => {
  store.dispatch("opportunitiesProduct/setActiveProfit", event);
}, 1000);

const greennProfit = computed({
  get() {
    return store.getters["opportunitiesProduct/getGreennProfit"]
        || store.getters['opportunities/getKittenTimeSettings'].length > 0;
  },
  set(event) {
    debouncedSetGreennProfit(event)
  },
});

// Fetch opportunity settings
async function getOpportunitiesSettings() {
  await store.dispatch("opportunitiesProduct/getOpportunitiesSettings");
}
// Define tabs with component references directly
const tabs = computed(() => [
  {
    title: "opportunities.title",
    component: QuestionsAnswers, // Directly reference the component
    show: Permissions.hasPermission("EditQuestionsAnswers"),
  },
  {
    title: "opportunities.time_title",
    component: KittenTimeProduct, // Directly reference the component
    show: Permissions.hasPermission("EditKittenTimeProduct"),
  },
]);

const opportunitySettings = computed({
  get() {
    let opportunitiesConfig = store.getters["opportunitiesProduct/getOpportunitiesSettings"];

    return JSON.parse(JSON.stringify(opportunitiesConfig));
  },
  set(event) {    
    return store.dispatch("opportunitiesProduct/setOpportunitySettings", event);
  },
});


const productQuestions = computed(() => {
  return store.getters["opportunitiesProduct/getOpportunitiesQuestionsAnswers"];
});

const selectedTab = ref(tabs.value[0]);


onMounted(async () => await getOpportunitiesSettings());
</script>

<style lang="scss">
.oportunity-profit {
  border-bottom: none !important;
  &.form-item .description {
    max-width: 70%;
  }
}

.oportunity-description {
  &.form-item {
    padding-bottom: 0 !important;
    border: none !important;
    .description {
      max-width: 70%;
    }
  }
}

.Advanced-questions {
  .Advanced__tabs {
    width: 100%;
    margin-bottom: 0 !important;
  }
}
</style>
